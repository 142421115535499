import React from "react";
import PropTypes from "prop-types";
import { kebabCase } from "lodash";
import { Helmet } from "react-helmet";
import { graphql, Link } from "gatsby";
import { Breadcrumb } from "gatsby-plugin-breadcrumb";

import Layout from "../components/Layout";
import Content, { HTMLContent } from "../components/Content";
import Scroller from "../components/Scroller";
import { StructuredData } from "../components/StructuredData";

export const BlogPostTemplate = ({
  content,
  contentComponent,
  description,
  tags,
  title,
  helmet,
  location,
  pageContext,
}) => {
  const PostContent = contentComponent || Content;

  const {
    breadcrumb: { crumbs },
  } = pageContext;

  const filterCrumbs = crumbs.filter((item) => item.crumbLabel != "blog");

  return (
    <>
      <section className="content-section">
        {helmet || ""}
        <div>
          <a href="https://blog.penny-finance.com">
            <img
              src="/img/home/logo.png"
              alt="penny icon"
              style={{
                height: "auto",
                maxWidth: "4%",
                minWidth: "80px",
                marginLeft: "20px",
              }}
            />
          </a>
        </div>

        <div className="container content">
          <div
            className="columns"
            style={{
              margin: "10px 0",
            }}
          >
            <div
              className="column is-10 is-offset-1"
              style={{
                fontFamily: "Neuzeit-Grotesk",
                fontSize: "20px",
              }}
            >
              <Breadcrumb
                crumbs={filterCrumbs}
                crumbSeparator=" | "
                crumbLabel={title}
                className="breadcrumb__link"
              />
              <h1 className="article-title has-text-weight-bold is-bold-light">
                {title}
              </h1>

              <PostContent content={content} />
              {tags && tags.length ? (
                <div style={{ marginTop: `4rem` }}>
                  <h4>Tags</h4>
                  <ul className="taglist">
                    {tags.map((tag) => (
                      <li key={tag + `tag`}>
                        <Link to={`/tags/${kebabCase(tag)}/`}>{tag}</Link>
                      </li>
                    ))}
                  </ul>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

BlogPostTemplate.propTypes = {
  content: PropTypes.node.isRequired,
  contentComponent: PropTypes.func,
  description: PropTypes.string,
  title: PropTypes.string,
  helmet: PropTypes.object,
  location: PropTypes.object,
  pageContext: PropTypes.object,
};

const BlogPost = ({ location, data, pageContext }) => {
  const { markdownRemark: post } = data;
  const frontmatter = post.frontmatter;
  const blogImageUrl = `https://blog.penny-finance.com${frontmatter.featuredimage.childImageSharp.fluid.src}`;
  const isBrowser = typeof window !== "undefined";

  return (
    <>
      <StructuredData>
        {{
          "@context": "http://schema.org",
          "@type": "Article",
          name: frontmatter.title,
          description: frontmatter.description,
          headline: frontmatter.title,
          image: blogImageUrl,
          datePublished: frontmatter.date,
          author: {
            "@type": "Organization",
            name: "Penny Finance",
            url: "https://www.penny-finance.com/",
          },
        }}
      </StructuredData>
      <Layout>
        <BlogPostTemplate
          content={post.html}
          contentComponent={HTMLContent}
          description={frontmatter.description}
          location={location}
          pageContext={pageContext}
          helmet={
            <Helmet titleTemplate="%s | Blog">
              <title>{frontmatter.title}</title>
              <meta name="description" content={frontmatter.description} />
              <meta property="og:type" content="article" />
              <meta property="og:title" content={frontmatter.title} />
              <meta
                property="og:description"
                content={frontmatter.description}
              />
              <meta
                property="og:url"
                content={`https://blog.penny-finance.com${location.pathname}`}
              />
              <meta property="og:image" content={blogImageUrl} />
              {
                isBrowser && <script>
                  {(function () {
                    var w = window, d = document, z = w.NeetoChat || {};
                    z.subdomain = "penny";
                    z.chatApiKey = "l2jMrhKazvbtplct";
                    z.neetoChatBaseUrl = "https://penny.neetochat.com";
                    z.neetoKbBaseUrl = "https://penny.neetokb.com";
                    z.neetoDeskBaseUrl = "https://penny.neetodesk.com";
                    w.NeetoChat = z;
                    var inserted = false;
                    function insertTag() {
                      if (inserted) return;
                      inserted = true;
                      var s = d.createElement("script");
                      s.type = "text/javascript";
                      s.async = true;
                      s.src = "https://d1andfazro43qm.cloudfront.net/bundle-loader.js";
                      var x = d.getElementsByTagName("script")[0];
                      x.parentNode.insertBefore(s, x);
                    }
                    if (document.readyState != "loading") {
                      insertTag();
                    } else {
                      document.onreadystatechange = function () {
                        if (document.readyState != "loading") insertTag();
                      };
                    }
                  })()}
                </script>
              }
              
              <script
                src="https://kit.fontawesome.com/af3bcee49a.js"
                crossorigin="anonymous"
              ></script>
              <script
                type="text/javascript"
                src="https://s.skimresources.com/js/206722X1686221.skimlinks.js"
              ></script>
            </Helmet>
          }
          tags={frontmatter.tags}
          title={frontmatter.title}
        />
        <div
          style={{
            textAlign: "center",
            margin: "25px",
            padding: "5px",
          }}
        >
          <p style={{ marginBottom: "20px", fontWeight: "bold" }}>
            Penny Finance may earn an affiliate commission if you purchase a
            partner product or service.
          </p>
          <a
            className="button-secondary | button"
            href="https://penny-finance.com"
          >
            take me to penny
          </a>
        </div>
        <Scroller />
      </Layout>
    </>
  );
};

BlogPost.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
};

export default BlogPost;

export const pageQuery = graphql`
  query BlogPostByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        title
        description
        tags
        featuredimage {
          childImageSharp {
            fluid {
              src
            }
          }
        }
      }
    }
  }
`;
